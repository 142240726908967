.email-verification{
  position: relative;
  z-index: 2;
}

.email-verification .content{
  min-height: 100vh;
  margin-top: 100px;
}

@media screen and (min-width: 768px) {
  .email-verification .success-con{
    margin: 5rem 8rem;
    padding: 5rem;
  }
}