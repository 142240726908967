.auth-sidebar {
  height: 100vh;
  width: 280px;
  /* background-color: #E7F5F6; */
  background-color: black;
  padding: 1rem 3rem;
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.brand-logo img {
  
  display: block;
  margin: 0 auto;
  margin-top: 3rem;
}

.trust-pilot {
  margin: 0 auto;
  margin-top: 2rem;
  height: 45px;
  display: block;
}

.contact-details {
  display: flex;
  gap: 20px;
  /* justify-content: center; */
  /* color: #29304e; */
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  align-items: center;
  padding: 1.25rem 0;
}

.privacy-info {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  /* color: #375b8e; */
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  /*  opacity: 0.5; */
}

.privacy-separator {
  width: 2px;
  background-color: #29C273;
  /* background-color: #375b8e; */
  /* border-radius: ; */
}

.sos-socials {
  display: flex;
  margin: 2rem 0;
  margin-bottom: 3rem;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 10px;
  font-size: 1.4rem;
  margin: 2rem 0;
  margin-bottom: 4rem;
}

.footer-links a {
  /* color: #324081; */
  color:#797d7c;
  font-weight: 500;
  font-size: 1.2rem;
}

.close-auth-sidebar {
  display: none;
}

@media screen and (max-width: 500px) {
  .auth-sidebar {
    width: 100%;
    padding: 1rem 8rem;
  }

  .close-auth-sidebar {
    display: block;
    margin: 1rem 0;
    position: absolute;
    right: 1rem;
  }

  .brand-logo img {
    height: 50px;
    margin-top: 2rem;
  }

  .trust-pilot {
    height: 33px;
  }

  .footer-links a {
    font-size: 1rem;
  }
}
